import React from 'react'
import './Blog.scss'
import { graphql } from 'gatsby'
import HeroBanner from '../../components/HeroBanner'
import CardContent from '../../components/CardContent'
import Layout from '../../components/Layout'
import { GenericWordpressToObject } from '../../util/WordpressUtil';

const Classes = ({ data }) => {

  return (
    <Layout>
      <div className="Classes">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="OCR Blog"
          caption="Ramblings from OCR Academy"
        />
        <div className="container content">
          <CardContent items={data.allWordpressPost.edges.map(GenericWordpressToObject)} modal={false} />
        </div>
      </div>
    </Layout>
  )
}

export default Classes

export const query = graphql`
  {
    allWordpressPost {
      edges {
        node {
          content
          excerpt
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
